<!-- 生物功能分析平台 -->
<template>
  <div>
    <div class="header">
      <div>
        <h3>{{ text9 }}</h3>
      </div>
    </div>
    <div class="content">
      <div class="wrap">
        <div class="content_nav">
          <router-link to="/platform/step">{{ text1 }}</router-link>
          <router-link to="/platform/biology" class="active">{{ text2 }}</router-link>
          <router-link to="/platform/cmc">{{ text3 }}</router-link>
        </div>
      </div>
      <div class="content_top">
        <h3>{{ text2 }}</h3>
        <p>{{ text5 }}</p>
        <p>{{ text6 }}</p>
        <p>{{ text7 }}</p>
      </div>
      <div class="content_bottom">
        <img src="https://jw-ppt.oss-cn-beijing.aliyuncs.com/zl/project/FutureGens/img/pingtai/pingtai_1.jpg" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
let language = localStorage.getItem("language") || "cn";
import { mapState } from "vuex";
export default {
  data() {
    switch (language) {
      case "cn":
        return {
          text1: "蛋白药物筛选优化STEP平台",
          text2: "免疫生物功能分析平台",
          text3: "CMC生产平台",
          text5: "完整的功能分析平台，打造优秀的抗体疗法",
          text6: "全面覆盖肿瘤免疫循环及所有免疫细胞筛选差异化分子",
          text7: "建立创新的多抗细胞水平生物学活性评价方法",
          text9: "平台技术",
        };
        break;
      case "en":
        return {
          text1: "STEP：Structure-based Targeted Evolution Platform",
          text2: "Immune Biological Function Analysis Platform",
          text3: "CMC Production Platform",
          text5: "A complete functional analysis platform to create excellent antibody therapeutics",
          text6: `Comprehensive coverage of tumor immune circulation and screening of differentiated molecules by all immune cells`,
          text7: "Establishment of an innovative method for evaluating the biological activity of polyclonal antibodies at the cellular level",
          text9: "Platform Technology",
        };
        break;
    }
  },
  computed: {
    ...mapState(["isLanguage"]),
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  .content_top {
    width: 100%;
    max-width: 1200px;
    text-align: center;
    color: #203864;
    margin: 0 auto;
    padding-bottom: 20px;
    h3 {
      position: relative;
      font-size: 50px;
      margin-bottom: 80px;
      margin-top: 150px;
      opacity: 0.9;
      font-weight: normal;
    }
    h3::after {
      content: "";
      position: absolute;
      width: 50px;
      height: 1px;
      background-color: #ccc;
      bottom: -10px;
      left: 50%;
      transform: translateX(-50%);
    }
    p {
      font-size: 18px;
      line-height: 1.8;
      text-align: left;
      width: 100%;
      margin: 0 auto;
    }
  }
  .content_bottom {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto 150px auto;
  }
  .content_bottom img {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .content {
    .content_top {
      h3 {
        width: 80vw;
        font-size: 30px;
        margin: 5vh auto;
      }
      p {
        width: 90vw;
        font-size: 16px;
        line-height: 1.8;
      }
    }
    .content_bottom {
      margin: 30px auto;
    }
  }
}
</style>
